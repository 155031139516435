<template>
  <v-container fluid>
    <v-row row>
      <v-col xs="12" lg12 md="12">
        <v-subheader>
          <h2>Produtores</h2>
        </v-subheader>
      </v-col>
    </v-row>

    <v-row row justify-space-between>
      <v-col xs="7" md="5">
        <v-text-field
          v-model="filter.term"
          ref="search"
          label="Faça a sua busca"
          clear-icon="close"
          clearable
          append-icon="search"
          @click:append="getData"
          @keyup="getData"
          @keyup.enter="getData"
        />
      </v-col>

      <v-col></v-col>

      <v-col class="text-xs-right d-flex">
        <v-btn
          class="flex-one ml-auto"
          color="primary"
          large
          to="suppliers/register"
        >
          + Incluir
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="table.headers"
      :items="table.items"
      :loading="table.loading"
      class="elevation-1"
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn :to="`/suppliers/edit/${item.id}`" icon v-on="on">
              <v-icon color="#707070">edit</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn @click="remove(item.id)" icon v-on="on">
              <v-icon color="#707070">clear</v-icon>
            </v-btn>
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <div>
      <v-card class="pa-3">
        <v-row wrap>
          <v-col xs="12" md="12" class="text-xs-center">
            <v-pagination
              v-model="table.pagination.page"
              :length="table.pagination.pages"
              v-bind:disabled="table.disabled"
              :total-visible="table.pagination.totalVisible"
              prev-icon="arrow_left"
              next-icon="arrow_right"
              primary
            />
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-container>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Suppliers",
  data() {
    return {
      expandFilters: false,
      filter: {
        term: "",
        active: "",
      },
      isImporting: false,
      userData: {},
      systemId: null,
      systemData: null,
      token: null,
      table: {
        headers: [
        // {
        //     text: "Código",
        //     align: "left",
        //     value: "code",
        //   },
          {
            text: "Nome",
            align: "left",
            value: "name",
          },
          {
            text: "CPF/CNPJ",
            align: "left",
            value: "cnpj",
          },
          {
            text: "Estado",
            align: "left",
            value: "uf",
          },
          {
            text: "Cidade",
            align: "left",
            value: "city_name",
          },
          {
            text: "",
            value: "actions",
            align: "right",
            sortable: false,
          },
        ],
        items: [],
        loading: false,
        // rowPerPageText: this.CONSTANTS.texts.table.rowPerPageText,
        pagination: {
          page: 1,
          perPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
          totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        },
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: "right",
        y: "top",
        multiLine: false,
      },
    };
  },
  async mounted() {
    this.filter.term = this.$functions.getSessionStorageSearchTerm("supplier");
    this.token = this.minamelServices.getToken();
  },

  methods: {
    async getData() {
      this.$functions.setSessionStorageSearchTerm("supplier", this.filter.term);

      try {
        this.table.loading = true;

        const params = {
          page: this.table.pagination.page,
          perPage: this.table.pagination.perPage,
          active: this.filter.active ? this.filter.active : "",
          term: this.filter.term ? this.filter.term : "",
          apiaryGroup: this.filter.apiaryGroup,
        };

        const res = await this.minamelServices
          .SupplierServiceV2()
          .getAll(params);

        this.table.items = res.data;
        this.table.pagination.pages = res.meta.last_page;
        this.table.loading = false;
      } catch (err) {
        this.table.loading = false;
      }
    },

    async remove(id) {
      const question = "Deseja realmente excluir este item?";
      const type = "question";

      const confirm = await this.alertConfirm(question, type);

      if (!confirm) {
        return;
      }

      const res = await this.minamelServices.SupplierServiceV2().deleteSupplierV2(id);

      if (res) {
        await this.getData();
      }
    },

    async alertConfirm(ask, type) {
      let response = await this.$swal.fire({
        title: `${ask}`,
        type: type,
        showCancelButton: true,
        confirmButtonColor: "#4caf50",
        cancelButtonColor: "#ff1744",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        allowOutsideClick: false,
      });

      if (response.value) {
        return true;
      }

      return false;
    },

    // importSuppliers() {
    //   sessionStorage.setItem("isImportingSuppliers", true);

    //   this.isImporting = true;
    //   this.table.loading = true;

    //   this.snackbar.color = "gray";
    //   this.snackbar.message = "Aguarde enquanto a importação está sendo feita";
    //   this.$root.$SnackBar.show(this.snackbar);

    //   this.$http
    //     .get(this.minamelServices.getUrl() + "suppliers/import", {
    //       params: {},
    //       headers: { token: this.token },
    //     })
    //     .then((response) => {
    //       if (response.body.error) {
    //         this.snackbar.color = "error";
    //         this.snackbar.message = response.body.message;
    //         this.$root.$SnackBar.show(this.snackbar);
    //       } else {
    //         this.snackbar.color = "success";
    //         this.snackbar.message = response.body.message;
    //         this.$root.$SnackBar.show(this.snackbar);
    //       }
    //     })
    //     .catch(() => {
    //       this.snackbar.color = "error";
    //       this.snackbar.message = this.CONSTANTS.texts.errors.connection;
    //       this.$root.$SnackBar.show(this.snackbar);
    //     })
    //     .finally(() => {
    //       sessionStorage.setItem("isImportingSuppliers", false);
    //       this.isImporting = false;
    //       this.table.loading = false;

    //       this.importNewUsers();
    //     });
    // },

    // async importNewUsers() {
    //   try {
    //     await this.$http.get(
    //       this.minamelServices.urls.v2 + "import/users/update",
    //       {
    //         params: {},
    //         headers: { token: this.token },
    //       }
    //     );
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
  },
  watch: {
    "$route.query.apiaryGroup": {
      immediate: true,
      handler(newValue) {
        this.filter.apiaryGroup = newValue ?? null;
        this.getData();
      }
    },

    "table.pagination.page": function () {
      this.getData();
    },
  },
};
</script>
