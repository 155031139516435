import Vue from "vue";
import { v1 as uuid } from "uuid";

/**
 * Singleton Variable
 * ! DONT REMOVE !
 */
let instance;

class TemplateStore {
    /**
     * Singleton constructor
     * @returns TemplateStore
     */
    constructor() {
        this.state = this.createState();

        if (!instance) {
            instance = this;
        }

        return instance;
    }

    get layoutList() {
        return [
            { value: 1, text: 'PMO - MINAMEL' },
            { value: 2, text: 'PMO - AAPINOR' },
            { value: 3, text: 'AUDITORIA - MINAMEL' },
            { value: 4, text: 'AUDITORIA - AAPINOR' },
            { value: 5, text: 'TERMO DE COMPROMETIMENTO - MINAMEL' },
            { value: 6, text: 'TERMO DE COMPROMETIMENTO - AAPINOR' },
            { value: 7, text: 'CONTRATO ORGÂNICO - MINAMEL' },
            { value: 8, text: 'CONTRATO ORGÂNICO - AAPINOR' },
            { value: 9, text: 'LISTA DE APIÁRIOS - MINAMEL' },
            { value: 10, text: 'LISTA DE APIÁRIOS - AAPINOR' },
            // { value: 11, text: 'LOCALIZAÇÃO - MINAMEL' },
            // { value: 12, text: 'LOCALIZAÇÃO - AAPINOR' },
            { value: 13, text: 'REGISTRO DE EQUIPAMENTO - MINAMEL' },
            { value: 14, text: 'REGISTRO DE EQUIPAMENTO - AAPINOR' },
            { value: 15, text: 'DECLARAÇÃO - MINAMEL' },
            { value: 16, text: 'DECLARAÇÃO - AAPINOR' },
            { value: 17, text: 'FSMA - MINAMEL' },
            { value: 18, text: 'FSMA - AAPINOR' },
        ];
    }

    createState() {
        return Vue.observable({
            error: '',
            loading: false,
            filled_by: '',
            template_id: null,
            layout: null,
            items: [],
            page: 1,
            perPage: 8,
            title: "",
        });
    }

    clearData() {
        this.state.filled_by = '';
        this.state.template_id = null;
        this.state.items = [];
        this.state.page = 1;
        this.state.perPage = 8;
        this.state.title = "";
        this.state.layout = null;
    }

    removeItem(item) {
        this.state.items = this.state.items.filter((formField) => {
            if (formField.id == item.id) {
                return false;
            }

            return true;
        });
    }

    reorderItem(item, fromIndex, toIndex) {
        this.state.items.splice(fromIndex, 1);

        setTimeout(() => {
            this.state.items.splice(toIndex, 0, item);
        }, 300);
    }

    addItem(item, index = 0) {
        this.state.items.splice(index, 0, item);
    }

    createItem() {
        return {
            id: uuid(),
            type: "TEXTAREA",
            is_required: 0,
            config: { options: [] },
        };
    }

    setLoading(value = false) {
        this.state.loading = value;
    }

    sortAllItems() {
        this.state.items.forEach((item, index) => {
            const toSerializedItem = this.state.items[index];
            toSerializedItem.index = index;
            toSerializedItem.filled_by = this.state.filled_by;

            if (toSerializedItem.help_text && toSerializedItem.help_text["preview"]) {
                toSerializedItem.help_text = toSerializedItem.help_text.preview;
            }
        });
    }
}

export default TemplateStore;