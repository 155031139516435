// Core
// cSpell: disable
import Vue from "vue";
import Router from "vue-router";
import VueSweetalert2 from "vue-sweetalert2";
import jwt from "vue-jwt-decode";

// import MinamelLibrary from 'minamel-lib';
import MinamelLibrary from "@/plugins/minamel-lib/src/main";

import MinamelServices from "@/plugins/minamel-services/main";

// Layouts
import AppView from "@/components/AppView";
import LoginView from "@/components/LoginView";

// Componentes
import Logoff from "@/components/view/Logoff";

import Dashboard from "@/components/view/Dashboard";
import DashboardInnBarrel from "@/components/view/DashboardInnBarrel";
import DashboardConectar from "@/components/view/DashboardConectar";
import DocumentModule from "@/modules/forms/presenter/routes.js";
import DocumentProjectModule from "@/modules/forms/presenter/routesOnProject.js";

Vue.prototype.minamelLibrary = MinamelLibrary;

Vue.prototype.minamelServices = new MinamelServices(
  {
    v1: process.env.VUE_APP_API_URL_V1,
    v2: process.env.VUE_APP_API_URL_V2,
  },
  () => (sessionStorage.token ? sessionStorage.token : null),
  () =>
    sessionStorage.token
      ? jwt.decode(sessionStorage.token).userData.lat_lng_type
      : null
);

Vue.use(VueSweetalert2);
Vue.use(Router);
const routes = [
  {
    name: "Login",
    path: "/login",
    component: LoginView,
    meta: {
      notRequiresAuth: true,
    },
  },
  {
    name: "Logoff",
    path: "/logoff",
    component: Logoff,
    meta: {
      notRequiresAuth: true,
    },
  },
  {
    path: "/",
    component: AppView,
    children: [
      { path: "/dashboard", name: "Dashboard", component: Dashboard },
      {
        path: "/dashboard-innbarrel",
        name: "DashboardInnBarrel",
        component: DashboardInnBarrel,
      },
      {
        path: "/dashboard-conectar",
        name: "DashboardConectar",
        component: DashboardConectar,
      },

      {
        path: "/movement-types",
        name: "MovementTypes",
        component: MinamelLibrary.MovementTypes,
      },
      {
        path: "/movement-types/register",
        name: "RegisterMovementType",
        component: MinamelLibrary.RegisterMovementType,
        props: true,
      },
      {
        path: "/movement-types/edit/:id",
        name: "EditMovementType",
        component: MinamelLibrary.RegisterMovementType,
        props: true,
      },

      {
        path: "/operations",
        name: "Operations",
        component: MinamelLibrary.Operations,
      },
      {
        path: "/operations/register",
        name: "RegisterOperation",
        component: MinamelLibrary.RegisterOperation,
        props: true,
      },
      {
        path: "/operations/edit/:id",
        name: "EditOperation",
        component: MinamelLibrary.RegisterOperation,
        props: true,
      },
      {
        path: "/operations/view/:id",
        name: "ViewOperation",
        component: MinamelLibrary.RegisterOperation,
        props: true,
      },

      {
        path: "/calendar",
        name: "Calendar",
        component: MinamelLibrary.Calendar,
      },

      {
        path: "/suppliers",
        name: "Suppliers",
        component: MinamelLibrary.Suppliers,
      },
      {
        path: "/suppliers-select",
        name: "SelectGroupSupplier",
        component: MinamelLibrary.SelectGroupSupplier,
      },
      {
        path: "/suppliers/register",
        name: "RegisterSupplier",
        component: MinamelLibrary.RegisterSupplier,
      },
      {
        path: "/suppliers/edit/:id",
        name: "RegisterSupplier",
        component: MinamelLibrary.RegisterSupplier,
        props: true,
      },

      {
        path: "/inventory-movements",
        name: "InventoryMovements",
        component: MinamelLibrary.InventoryMovements,
      },

      {
        path: "/aggregates",
        name: "Aggregates",
        component: MinamelLibrary.Aggregates,
      },
      {
        path: "/suppliers/:id/register",
        name: "RegisterAggregate",
        component: MinamelLibrary.RegisterAggregate,
      },
      {
        path: "/suppliers/edit/aggregates/:id",
        name: "EditAggregate",
        component: MinamelLibrary.RegisterAggregate,
        props: true,
      },

      { path: "/users", name: "Users", component: MinamelLibrary.Users },
      {
        path: "/users/register",
        name: "RegisterUser",
        component: MinamelLibrary.RegisterUser,
      },
      {
        path: "/users/edit/:id",
        name: "EditUser",
        component: MinamelLibrary.RegisterUser,
        props: true,
      },

      {
        path: "/user-groups",
        name: "UserGroups",
        component: MinamelLibrary.UserGroups,
      },
      {
        path: "/user-groups/register",
        name: "RegisterUserGroup",
        component: MinamelLibrary.RegisterUserGroup,
      },
      {
        path: "/user-groups/edit/:id",
        name: "EditUserGroup",
        component: MinamelLibrary.RegisterUserGroup,
        props: true,
      },

      {
        path: "/apiary-groups",
        name: "ApiaryGroups",
        component: MinamelLibrary.ApiaryGroups,
      },
      {
        path: "/apiary-groups/register",
        name: "RegisterApiaryGroup",
        component: MinamelLibrary.RegisterApiaryGroup,
      },
      {
        path: "/apiary-groups/edit/:id",
        name: "EditApiaryGroup",
        component: MinamelLibrary.RegisterApiaryGroup,
        props: true,
      },

      {
        path: "/apiaries",
        name: "Apiary",
        component: MinamelLibrary.Apiary,
        props: true,
      },
      {
        path: "/apiaries-select",
        name: "SelectGroup",
        component: MinamelLibrary.SelectGroup,
      },
      {
        path: "/apiaries/register",
        name: "RegisterApiary",
        component: MinamelLibrary.RegisterApiary,
      },
      {
        path: "/apiaries/edit/:id",
        name: "EditApiary",
        component: MinamelLibrary.RegisterApiary,
        props: true,
      },
      {
        path: "supplier/apiaries/edit/:id",
        name: "EditApiaryOnSupplier",
        component: MinamelLibrary.RegisterSupplier,
        props: true,
      },

      {
        path: "/circle-maps",
        name: "CircleMaps",
        component: MinamelLibrary.CircleMaps,
      },
      {
        path: "/circle-maps/register",
        name: "RegisterCircleMap",
        component: MinamelLibrary.RegisterCircleMap,
      },
      {
        path: "/circle-maps/edit/:id",
        name: "EditCircleMap",
        component: MinamelLibrary.RegisterCircleMap,
        props: true,
      },

      { path: "/flowery", name: "Flowery", component: MinamelLibrary.Flowery },
      {
        path: "/flowery/register",
        name: "RegisterFlowery",
        component: MinamelLibrary.RegisterFlowery,
      },
      {
        path: "/flowery/edit/:id",
        name: "EditFlowery",
        component: MinamelLibrary.RegisterFlowery,
        props: true,
      },

      { path: "/project", name: "Project", component: MinamelLibrary.Project },
      {
        path: "/project/register",
        name: "RegisterProject",
        component: MinamelLibrary.RegisterProject,
      },
      // { path: '/project/edit/:id?', name: 'EditProject', component: MinamelLibrary.RegisterProject, props: true },
      {
        path: "/project/edit/:id",
        name: "EditProject",
        component: MinamelLibrary.RegisterProject,
        props: true,
        children: [
          { path: "data", component: MinamelLibrary.RegisterProject },

          {
            path: "apiaries",
            name: "Apiary",
            component: MinamelLibrary.Apiary,
          },
          {
            path: "apiaries/edit/:childrenId",
            component: MinamelLibrary.RegisterApiary,
          },

          {
            path: "honey-appointments",
            name: "HoneyAppointments",
            component: MinamelLibrary.HoneyAppointments,
          },
          {
            path: "honey-appointments/edit/:childrenId",
            component: MinamelLibrary.HoneyAppointmentsRegister,
          },

          {
            path: "cleaning-records",
            component: MinamelLibrary.CleaningRecords,
          },
          {
            path: "cleaning-records/edit/:childrenId",
            component: MinamelLibrary.CleaningRecordsRegister,
          },

          {
            path: "bee-swarm-captures",
            component: MinamelLibrary.BeeSwarmCaptures,
          },
          {
            path: "bee-swarm-captures/edit/:childrenId",
            component: MinamelLibrary.BeeSwarmCapturesRegister,
          },

          {
            path: "beeswax-appointments",
            component: MinamelLibrary.BeeswaxAppointments,
          },
          {
            path: "beeswax-appointments/edit/:childrenId",
            component: MinamelLibrary.BeeswaxAppointmentsRegister,
          },

          {
            path: "disease-treatment-records",
            component: MinamelLibrary.DiseaseTreatmentRecords,
          },
          // { path: 'disease-treatment-records/register', component: MinamelLibrary.DiseaseTreatmentRecordsRegister },
          {
            path: "disease-treatment-records/edit/:childrenId",
            component: MinamelLibrary.DiseaseTreatmentRecordsRegister,
          },

          { path: "feed-records", component: MinamelLibrary.FeedRecords },
          // { path: 'feed-records/register', component: MinamelLibrary.FeedRecordsRegister },
          {
            path: "feed-records/edit/:childrenId",
            component: MinamelLibrary.FeedRecordsRegister,
          },

          { path: "forms", component: MinamelLibrary.Forms },
          {
            path: "forms/edit/:childrenId",
            name: "EditForms",
            component: MinamelLibrary.FormsRegister,
          },
          {
            path: "responses",
            name: "FormsResponse",
            component: MinamelLibrary.FormResponses,
          },
          ...DocumentProjectModule,

          { path: "attachments", component: MinamelLibrary.ProjectAttachments },

          { path: "notifications", component: MinamelLibrary.ProjectDates },
          {
            path: "notifications/register",
            component: MinamelLibrary.ProjectDatesRegister,
          },
          {
            path: "notifications/edit/:childrenId",
            component: MinamelLibrary.ProjectDatesRegister,
          },
        ],
      },

      { path: "/systems", name: "Systems", component: MinamelLibrary.Systems },
      {
        path: "/systems/register",
        name: "RegisterSystem",
        component: MinamelLibrary.RegisterSystem,
      },
      {
        path: "/systems/edit/:id?",
        name: "EditSystem",
        component: MinamelLibrary.RegisterSystem,
        props: true,
      },

      { path: "/roles", name: "Roles", component: MinamelLibrary.Roles },
      {
        path: "/roles/register",
        name: "RegisterRole",
        component: MinamelLibrary.RegisterRole,
      },
      {
        path: "/roles/edit/:id?",
        name: "EditRole",
        component: MinamelLibrary.RegisterRole,
        props: true,
      },

      {
        path: "/my-user",
        name: "MyUser",
        component: MinamelLibrary.MyUser,
        props: true,
      },

      {
        path: "/supplier-balance",
        name: "SupplierBalance",
        component: MinamelLibrary.SupplierBalance,
        props: true,
      },

      {
        path: "/system-variables",
        name: "SystemVariables",
        component: MinamelLibrary.SystemVariables,
      },
      {
        path: "/system-variables/register",
        name: "RegisterSystemVariables",
        component: MinamelLibrary.RegisterSystemVariables,
      },
      {
        path: "/system-variables/edit/:id?",
        name: "EditSystemVariables",
        component: MinamelLibrary.RegisterSystemVariables,
        props: true,
      },

      {
        path: "/field-notes",
        name: "FieldNotes",
        component: MinamelLibrary.FieldNotes,
        props: true,
        children: [
          { path: "", component: MinamelLibrary.HoneyAppointments },
          {
            path: "honey-appointments",
            component: MinamelLibrary.HoneyAppointments,
          },
          {
            path: "honey-appointments/register",
            component: MinamelLibrary.HoneyAppointmentsRegister,
          },
          {
            path: "honey-appointments/edit/:id",
            component: MinamelLibrary.HoneyAppointmentsRegister,
          },

          {
            path: "cleaning-records",
            component: MinamelLibrary.CleaningRecords,
          },
          {
            path: "cleaning-records/register",
            component: MinamelLibrary.CleaningRecordsRegister,
          },
          {
            path: "cleaning-records/edit/:id",
            component: MinamelLibrary.CleaningRecordsRegister,
          },

          {
            path: "bee-swarm-captures",
            component: MinamelLibrary.BeeSwarmCaptures,
          },
          {
            path: "bee-swarm-captures/register",
            component: MinamelLibrary.BeeSwarmCapturesRegister,
          },
          {
            path: "bee-swarm-captures/edit/:id",
            component: MinamelLibrary.BeeSwarmCapturesRegister,
          },

          {
            path: "beeswax-appointments",
            component: MinamelLibrary.BeeswaxAppointments,
          },
          {
            path: "beeswax-appointments/register",
            component: MinamelLibrary.BeeswaxAppointmentsRegister,
          },
          {
            path: "beeswax-appointments/edit/:id",
            component: MinamelLibrary.BeeswaxAppointmentsRegister,
          },

          {
            path: "disease-treatment-records",
            component: MinamelLibrary.DiseaseTreatmentRecords,
          },
          {
            path: "disease-treatment-records/register",
            component: MinamelLibrary.DiseaseTreatmentRecordsRegister,
          },
          {
            path: "disease-treatment-records/edit/:id",
            component: MinamelLibrary.DiseaseTreatmentRecordsRegister,
          },

          { path: "feed-records", component: MinamelLibrary.FeedRecords },
          {
            path: "feed-records/register",
            component: MinamelLibrary.FeedRecordsRegister,
          },
          {
            path: "feed-records/edit/:id",
            component: MinamelLibrary.FeedRecordsRegister,
          },
        ],
      },
      {
        path: "/documents",
        name: "Documents",
        component: MinamelLibrary.Documents,
        props: true,
        children: [
          // all new refactor or routes going on this module
          ...DocumentModule,
          { path: "", redirect: "forms" },
          { path: "forms", name: "Forms", component: MinamelLibrary.Forms },
          {
            path: "forms/view/:id",
            name: "ViewForms",
            component: MinamelLibrary.FormsView,
          },
          {
            path: "responses",
            name: "FormsResponse",
            component: MinamelLibrary.FormResponses,
          },
        ],
      },
      {
        path: "/document-templates",
        name: "DocumentTemplates",
        component: MinamelLibrary.DocumentTemplates,
      },
      {
        path: "/document-templates/register",
        name: "DocumentTemplateRegister",
        component: MinamelLibrary.DocumentTemplateRegister,
      },
      {
        path: "/document-templates/edit/:id",
        name: "DocumentTemplateRegister",
        component: MinamelLibrary.DocumentTemplateRegister,
      },
    ],
  },
];
const router = new Router({ routes, mode: "history" });

window.popStateDetected = false;
window.addEventListener("popstate", () => {
  window.popStateDetected = true;
});

router.beforeEach(async (to, from, next) => {
  const userData = sessionStorage.token
    ? jwt.decode(sessionStorage.token).userData
    : null;
  const systemId = sessionStorage.systemId ? sessionStorage.systemId : null;
  const systemData = sessionStorage.token
    ? jwt.decode(sessionStorage.token).systemData[systemId]
    : null;

  const publicPages = ["/logoff", "/login"];
  // let new_to_path = Object.keys(to.params).length ? to.path.replace(`/${to.params.id}`, '') : to.path;
  // let finalPath = userData && userData.roles.includes(new_to_path) ? to.path : (userData && userData.roles.includes(from.path) ? from.path : '/login');

  // finalPath = publicPages.includes(finalPath) || finalPath == '' ? '/login' : finalPath;
  // finalPath = publicPages.includes(finalPath);
  let isPublic = publicPages.includes(to.path);
  // if (finalPath != to.path) {
  // 	next(finalPath);
  // }
  // if (to.meta.notRequiresAuth) {
  // 	next();
  // }

  if (isPublic) {
    return next();
  }

  if (!isPublic && !userData) {
    sessionStorage.clear();

    return next("/login");
  }

  const instance = router.app;

  instance.minamelServices
    .LoginService()
    .loginChecktoken()
    .search()
    .then((response) => {
      if (response.error) {
        sessionStorage.clear();

        return next("/login");
      }

      if (response.result && response.status) {
        sessionStorage.setItem("token", response.result);
      }

      document.title = decodeURIComponent(escape(systemData.name));

      return next();
    })
    .catch((e) => {
      console.error("Router::index - error: ", e);
      sessionStorage.clear();

      return next("/login");
    });

  next();
});

export default router;
